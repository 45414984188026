<template>
	<div class="full">
		<vue-scroll-snap class="page" :fullscreen='true' :horizontal='false'>
			<vue-scroll-snap class="item page-mini" :fullscreen='true' :horizontal='true'>
				<div class="item page red">
					<div class="title">Page 1</div>
					<div class="title">Product 1</div>
					<span class="right material-icons">chevron_right</span>
				</div>
				<div class="item page blue">
					<div class="title">Product 2</div></div>
				<div class="item page green">
					<div class="title">Product 3</div></div>
				<div class="item page red">
					<div class="title">Product 4</div></div>
				<div class="item page blue">
					<div class="title">Product 5</div></div>
				<div class="item page green">
					<div class="title">Product 6</div></div>
				<span class="down material-icons">expand_more</span>
			</vue-scroll-snap>
			<div class="item page blue"><div class="title">Page 2</div></div>
			<div class="item page red"><div class="title">Page 3</div></div>
			<div class="item page green"><div class="title">Page 4</div></div>
			<div class="item page blue"><div class="title">Page 5</div></div>
			<div class="item page red"><div class="title">Page 6</div></div>
			<div class="item page green"><div class="title">Page 7</div></div>
		</vue-scroll-snap> 
	</div>
</template>

<script>
import VueScrollSnap from "vue-scroll-snap";
export default {


	components: {VueScrollSnap},
	methods: {
		init () {
			let t = "innerWidth: " + window.innerWidth +'\n'+
							"innerHeight: " + window.innerHeight
			console.log(t);
		}
	},
	mounted () {
		this.init()
	}

}
</script>

<style lang='scss' scoped>

@keyframes floatY {
		50% {
				/* box-shadow: 0 5px 15px 0px rgba(0,0,0,0.6); */
				transform: translatey(0px);
		}
		75% {
				/* box-shadow: 0 25px 15px 0px rgba(0,0,0,0.2); */
				transform: translatey(10px);
		}
		100% {
				/* box-shadow: 0 5px 15px 0px rgba(0,0,0,0.6); */
				transform: translatey(0px);
		}
}
@keyframes floatX {
		50% {
				/* box-shadow: 0 5px 15px 0px rgba(0,0,0,0.6); */
				transform: translatex(0px);
		}
		75% {
				/* box-shadow: 0 25px 15px 0px rgba(0,0,0,0.2); */
				transform: translatex(10px);
		}
		100% {
				/* box-shadow: 0 5px 15px 0px rgba(0,0,0,0.6); */
				transform: translatex(0px);
		}
}
.down {
	animation: floatY 3s ease-in-out infinite;
	position: absolute;
	font-size: 50px;
	bottom: 10px;
	width: 100%;
}
.right {
	// transform: rotate(90deg);
	animation: floatX 3s ease-in-out infinite;
	position: absolute;
	font-size: 50px;
	right: 10px;
	top: 50%;
	// height: 100%;
}
.full {
	width: 100vw;
	height: 100vh;
}
.page {
	position: relative;
	width: 100%;	
	height: 100%;
}
.page-mini {
	position: relative;
	width: 90%;
	height: 90%;
}
.red {
	background: rgb(250, 115, 115);
}
.green {
	background: rgb(126, 224, 134);
}
.blue {
	background: rgb(93, 164, 245);
}
</style>